import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Dialog } from '../Dialog';

import { classes } from './InputDialog.st.css';

interface IInputDialogProps extends React.ComponentProps<typeof Dialog> {}

export function InputDialog(props: IInputDialogProps) {
  const { className, ...rest } = props;
  const { isMobile } = useEnvironment();

  return (
    <Dialog
      fullscreen={isMobile}
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
      })}
      {...rest}
    />
  );
}

InputDialog.displayName = 'InputDialog';
