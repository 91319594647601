import React from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';

import { useController } from 'common/context/controller';
import { useRouter } from 'common/router';
import { getLocaleDateString } from 'common/utils/date';

import { selectFuturePlans } from 'store/groups/selectors';
import { selectDialog } from 'store/application/selectors';

import { Button } from 'wui/Button';
import { AlertDialog } from 'wui/AlertDialog';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogContentText } from 'wui/DialogContentText';
import { DialogActions } from 'wui/DialogActions';

import { usePricingPlansQueryParams } from './usePricingPlansQueryParams';
import { usePaidPlansBi } from './bi';

export const FuturePlanDialog: React.FC = () => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const { application$ } = useController();

  const router = useRouter();

  const bi = usePaidPlansBi();

  const dialog = useSelector(selectDialog('futurePlanDialog'));
  const groupId = dialog.params?.groupId as string;

  const futurePlans = useSelector(selectFuturePlans(groupId));
  const queryParams = usePricingPlansQueryParams(groupId);

  if (!futurePlans.length) {
    return null;
  }

  const date = getLocaleDateString(new Date(`${futurePlans[0].startsAt}`));

  return (
    <AlertDialog isOpen={dialog.isOpen} onClose={handleClose}>
      <DialogTitle
        alert
        title={t('groups-web.restriction.plans.future.dialog.title')}
      />
      <DialogContent>
        <DialogContentText>
          {t('groups-web.restriction.plans.future.dialog.subtitle', {
            date,
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions alert>
        <Button bw secondary onClick={handleClose} fullWidth={isMobile}>
          {t('groups-web.restriction.plans.future.dialog.cancel')}
        </Button>
        <Button bw onClick={handleSubmit} fullWidth={isMobile}>
          {t('groups-web.restriction.plans.future.dialog.cta')}
        </Button>
      </DialogActions>
    </AlertDialog>
  );

  function handleSubmit() {
    bi.getAnotherPlanClick();
    router.go('pricing-plans', {
      appSectionParams: queryParams,
    });
  }

  function handleClose() {
    application$.closeDialog('futurePlanDialog');
  }
};
