import React from 'react';
import cls from 'classnames';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { classes } from './DialogContent.st.css';

interface IDialogContentProps extends TPAComponentProps {
  divider?: boolean;
  children: React.ReactNode;
}

export function DialogContent({
  className,
  divider,
  children,
  ...restProps
}: IDialogContentProps) {
  return (
    <div
      className={cls(classes.root, className, {
        [classes.divider]: divider,
      })}
      {...restProps}
    >
      {children}
    </div>
  );
}
