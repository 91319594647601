import React from 'react';
import { useSelector } from 'react-redux';

import { useController } from 'common/context/controller';
import { filterEvents } from 'common/components/Event/helpers';
import { getEventPage } from 'common/components/Event/getEventPage';

import { selectDialog } from 'store/application/selectors';
import { selectEventsRestricted } from 'store/groups/selectors';

import { EventListDialog } from './EventListDialog';
import { UpcomingEventsDialog } from './UpcomingEventsDialog';
import { PastEventsDialog } from './PastEventsDialog';

export const EventsRestrictionDialog: React.FC = () => {
  const [isListDialogOpened, setListDialogOpened] = React.useState(false);

  const { application$ } = useController();

  const dialog = useSelector(selectDialog('eventsRestriction'));
  const groupId = dialog.params?.groupId as string;
  const events = useSelector(selectEventsRestricted(groupId));

  const [upcoming, past] = React.useMemo(() => {
    return filterEvents(events);
  }, [events]);

  if (!upcoming.length) {
    return (
      <PastEventsDialog
        events={past}
        isOpen={dialog.isOpen}
        onClose={handleClose}
      />
    );
  }

  return (
    <>
      <UpcomingEventsDialog
        events={upcoming}
        isOpen={dialog.isOpen}
        onClose={handleClose}
        onSubmit={viewEvents}
      />
      <EventListDialog
        events={upcoming}
        isOpen={isListDialogOpened}
        onClose={closeListDialog}
      />
    </>
  );

  function handleClose() {
    application$.closeDialog('eventsRestriction');
  }

  function closeListDialog() {
    setListDialogOpened(false);
  }

  function viewEvents() {
    if (upcoming.length === 1) {
      const eventPage = getEventPage(upcoming[0].eventPageUrl);
      goToEventPage(eventPage);
    } else {
      setListDialogOpened(true);
    }
  }
};

function goToEventPage(eventPage: string | undefined) {
  if (!eventPage) {
    return;
  }
  window.open(eventPage, '_blank');
}
