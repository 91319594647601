import React, { useEffect, useMemo, useState } from 'react';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';
import { uniq } from 'lodash';

import { ButtonPriority, Spinner } from 'wix-ui-tpa';

import {
  selectMemberLabel,
  selectIsGroupSecret,
  selectIsGroupPending,
  selectMembersAddWidgetLabel,
} from 'store/groups/selectors';
import {
  selectInviteStatuses,
  selectSuggestedMembersMetadata,
} from 'store/members/selectors';

import { isValidEmail } from 'common/utils/utils';
import { Search } from 'common/components/Search';
import { Modal } from 'common/components/Modal';
import { Button } from 'common/components/Button/Button';
import { ModalV2 } from 'common/components/Modal/ModalV2';
import { useController } from 'common/context/controller';

import { ADD_MEMBERS_BUTTON } from '../dataHooks';

import { SuggestedMembersList } from './SuggestedMembersList';
import { EmailInvitesList } from './EmailInvitesList';
import { useSearchPlaceHolder } from './useSearchPlaceholder';

import { classes } from './InviteMembersModal.st.css';
import { groupsAddMemberClicked, inviteSent } from '@wix/bi-logger-groups/v2';
import { BIUserEntry } from 'common/bi-logger/types';
import { INVITE_MEMBERS_MODAL } from 'Group/Widget/Header/InviteMembers/InviteMembersModal/dataHook';

interface IInviteMembersModalProps {
  groupId: string;
  isOpen: boolean;

  onClose(): void;
}

export function InviteMembersModal(props: IInviteMembersModalProps) {
  const { isOpen, groupId, onClose } = props;

  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();
  const { members$ } = useController();

  const { label: membersLabel } = useSelector(selectMemberLabel(groupId));
  const { label: membersAddWidgetLabel } = useSelector(
    selectMembersAddWidgetLabel(groupId),
  );
  const isSecret = useSelector(selectIsGroupSecret(groupId));
  const isPending = useSelector(selectIsGroupPending(groupId));
  const meta = useSelector(selectSuggestedMembersMetadata);
  const status = useSelector(selectInviteStatuses);

  const placeholder = useSearchPlaceHolder({
    isSecret,
    count: meta.metadata.total as number,
  });

  const [selected, setSelected] = useState<string[]>([]);
  const [search, setSearch] = useState('');

  const emails = useMemo<string[]>(() => {
    return uniq(
      search
        .split(',')
        .filter(isValidEmail)
        .map((email) => email.trim()) || [],
    );
  }, [search]);

  useEffect(() => {
    setSelected([]);
    setSearch('');
  }, [isOpen]);

  if (isPending) {
    return (
      <Modal data-hook={INVITE_MEMBERS_MODAL} isOpen={isOpen} onClose={onClose}>
        <Modal.Header
          title={t('groups-web.discussion.can-not-add-members-popup.title')}
          subtitle={t(
            'groups-web.discussion.can-not-add-members-popup.subtitle',
          )}
        />
        <Modal.Buttons>
          <Button bw priority={ButtonPriority.primary} onClick={onClose}>
            {t('groups-web.discussion.can-not-add-members-popup.back-to-group')}
          </Button>
        </Modal.Buttons>
      </Modal>
    );
  }

  const isLoading = selected.some((id) => status[id]?.loading);

  return (
    <ModalV2 data-hook={INVITE_MEMBERS_MODAL} isOpen={isOpen} onClose={onClose}>
      <ModalV2.Title>
        {t(membersAddWidgetLabel, { membersLabel })}
      </ModalV2.Title>
      <ModalV2.Content>
        <div className={classes.filters}>
          <Search
            forceOpen
            fullWidth
            value={search}
            onChange={handleSearchChange}
            className={classes.search}
            withBorder={!isMobile}
            placeholder={placeholder}
          />
        </div>
        {emails.length ? (
          <EmailInvitesList
            emails={emails}
            selected={selected}
            onSelect={handleSelect}
          />
        ) : (
          <SuggestedMembersList
            isSecret={isSecret}
            groupId={groupId}
            search={search}
            selected={selected}
            onSelect={handleSelect}
          />
        )}
      </ModalV2.Content>
      <ModalV2.Footer
        okButton={
          <Button
            bw
            disabled={isLoading || !selected.length}
            onClick={handleSubmit}
            data-hook={ADD_MEMBERS_BUTTON}
          >
            {isLoading ? <Spinner diameter={20} /> : t('groups-web.add')}
          </Button>
        }
      />
    </ModalV2>
  );

  function handleSubmit() {
    if (emails.length) {
      members$.inviteByEmail(groupId, emails);
      bi.report(
        inviteSent({
          origin: 'wix',
          userEntry: BIUserEntry.SITE,
          group_id: groupId,
          contact: emails.join(', '),
        }),
      );
    } else {
      members$.add(groupId, selected);
      bi.report(
        groupsAddMemberClicked({
          groupId,
          origin: 'modal_plus_add_btn',
          userEntry: BIUserEntry.SITE,
        }),
      );
    }
  }

  function handleSearchChange(value: string) {
    setSearch(value);
  }

  function handleSelect(selected: string[]) {
    setSelected(selected);
  }
}

InviteMembersModal.displayName = 'InviteMembersModal';
