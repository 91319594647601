import React from 'react';
import { useSelector } from 'react-redux';
import { groupActionClick } from '@wix/bi-logger-groups/v2';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { useController } from 'common/context/controller';
import { BIUserEntry } from 'common/bi-logger/types';
import { BlackAndWhiteThreeDots } from 'common/components/BlackAndWhiteThreeDots';

import {
  selectGroup,
  selectIsGroupSecret,
  selectHasAdminRole,
  selectHasMemberRole,
  selectIsJoinedGroupMember,
  selectIsUnknownGroupMember,
} from 'store/groups/selectors';

import { GroupSettings } from './GroupSettings';
import { NotificationSettingsComponent as NotificationSettings } from './NotificationSettings';

import { DeleteDialog } from './DeleteDialog';
import { GroupAction } from './GroupAction';
import { GROUP_ACTIONS_THREE_DOTS } from './dataHooks';

interface GroupActionsProps extends TPAComponentProps {
  groupId: string;
  onShare(): void;
}

export const GroupActions: React.FC<GroupActionsProps> = (props) => {
  const { groupId } = props;
  const bi = useBi();
  const { t } = useTranslation();
  const { group$, application$ } = useController();
  const [isActionsMenuOpened, setIsActionsMenuOpened] = React.useState(false);
  const [openedDialog, setOpenedDialog] = React.useState<GroupAction | null>(
    null,
  );

  const group = useSelector(selectGroup(groupId));
  const isGroupSecret = useSelector(selectIsGroupSecret(groupId));

  const isJoined = useSelector(selectIsJoinedGroupMember(groupId));
  const isUnknown = useSelector(selectIsUnknownGroupMember(groupId));

  const isAdmin = useSelector(selectHasAdminRole(groupId));
  const isMember = useSelector(selectHasMemberRole(groupId));

  const menuItems = React.useMemo(() => {
    if (!isJoined) {
      return [];
    }

    return [
      {
        onClick: openNotificationSettingsDialog,
        content: t(GroupAction.NOTIFICATION_SETTINGS),
      },
      isAdmin
        ? [
            {
              onClick: openGroupSettingsDialog,
              content: t(GroupAction.GROUP_SETTINGS),
            },
          ]
        : [],
      isGroupSecret
        ? []
        : [
            {
              onClick: handleOpenShareDialog,
              content: t(GroupAction.SHARE),
            },
          ],
      {
        onClick: handleOpenLeaveDialog,
        content: t(GroupAction.LEAVE),
      },
      isAdmin
        ? [
            {
              onClick: openDeleteDialog,
              content: t(GroupAction.DELETE),
            },
          ]
        : [],
    ].flat();
  }, [isAdmin, isMember, isJoined, isGroupSecret]);

  if (isUnknown || menuItems.length === 0) {
    return null;
  }

  return (
    <>
      <BlackAndWhiteThreeDots
        data-hook={GROUP_ACTIONS_THREE_DOTS}
        items={menuItems}
        isOpen={isActionsMenuOpened}
        onOpen={handleOpenThreeDots}
        onClose={handleCloseThreeDots}
        iconClassName={props.className}
      />

      {isAdmin && (
        <DeleteDialog
          isOpen={openedDialog === GroupAction.DELETE}
          onClose={closeDialog}
          groupTitle={group.name ?? ''}
          onDelete={handleDeleteGroup}
        />
      )}

      <GroupSettings
        groupId={groupId}
        isOpen={openedDialog === GroupAction.GROUP_SETTINGS}
        onClose={closeDialog}
      />

      <NotificationSettings
        groupId={groupId}
        isOpen={openedDialog === GroupAction.NOTIFICATION_SETTINGS}
        onClose={closeDialog}
      />
    </>
  );

  function handleOpenShareDialog() {
    setIsActionsMenuOpened(false);
    props.onShare();
  }

  function openDeleteDialog() {
    bi.report(
      groupActionClick({
        action: 'delete',
        group_id: groupId,
        origin: 'menu',
        userEntry: BIUserEntry.SITE,
      }),
    );

    setIsActionsMenuOpened(false);
    setOpenedDialog(GroupAction.DELETE);
  }

  function handleOpenLeaveDialog() {
    bi.report(
      groupActionClick({
        action: 'leave',
        group_id: groupId,
        origin: 'menu',
        userEntry: BIUserEntry.SITE,
      }),
    );

    setIsActionsMenuOpened(false);
    application$.showDialog({
      dialog: 'leaveGroup',
      params: { groupId },
    });
  }

  function handleCloseThreeDots() {
    setIsActionsMenuOpened(false);
  }

  function handleOpenThreeDots() {
    setIsActionsMenuOpened(true);
  }

  function closeDialog() {
    setOpenedDialog(null);
  }

  function openNotificationSettingsDialog() {
    bi.report(
      groupActionClick({
        action: 'notification_settings',
        group_id: groupId,
        origin: 'menu',
        userEntry: BIUserEntry.SITE,
      }),
    );

    setIsActionsMenuOpened(false);
    setOpenedDialog(GroupAction.NOTIFICATION_SETTINGS);
  }

  function openGroupSettingsDialog() {
    bi.report(
      groupActionClick({
        group_id: groupId,
        action: 'group_settings',
        origin: 'menu',
        userEntry: BIUserEntry.SITE,
      }),
    );

    setIsActionsMenuOpened(false);
    setOpenedDialog(GroupAction.GROUP_SETTINGS);
  }

  function handleDeleteGroup() {
    bi.report(
      groupActionClick({
        action: 'delete',
        group_id: groupId,
        origin: 'modal_dialog_button',
        userEntry: BIUserEntry.SITE,
      }),
    );

    void group$.remove(groupId);
  }
};

GroupActions.displayName = 'GroupActions';
