import React, { useEffect } from 'react';
import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { useSelector } from 'react-redux';
import { SwitchTransition } from 'react-transition-group';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import type { JoinRequest } from '@wix/ambassador-social-groups-v2-group-member/types';

import { selectDialog } from 'store/application/selectors';
import {
  selectGroup,
  selectGroupQuestions,
  selectGroupStatus,
} from 'store/groups/selectors';

import { useController } from 'common/context/controller';

import { Fade } from 'wui/Fade';
import { InputDialog } from 'wui/InputDialog';
import { Button } from 'wui/Button';
import { DialogTitle } from 'wui/DialogTitle';
import { DialogContent } from 'wui/DialogContent';
import { DialogActions } from 'wui/DialogActions';
import { List } from 'wui/List';

import {
  GROUP_QUESTIONS_DIALOG_ROOT,
  GROUP_QUESTIONS_DIALOG_SUBMIT_BUTTON,
} from './dataHooks';
import { GroupQuestion, GroupQuestionSkeleton } from './GroupQuestion';

import { GroupMembershipButton } from '../../GroupMembershipButton';

export function GroupQuestionsDialog() {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const { application$, group$ } = useController();

  const dialog = useSelector(selectDialog('groupQuestions'));
  const groupId = dialog.params?.groupId as string;
  const group = useSelector(selectGroup(groupId));
  const status = useSelector(selectGroupStatus(groupId));
  const questions = useSelector(selectGroupQuestions(groupId));

  const validationSchema = Yup.object().shape(
    questions.reduce((acc, current) => {
      let schema = Yup.string().max(
        100,
        t('groups-web.membership-questions.response.error-max_length'),
      );

      if (current.required) {
        schema = schema
          .clone()
          .required(t('groups-web.membership-questions.response.error'));
      }

      acc[current.id as string] = schema;

      return acc;
    }, {} as Record<string, any>),
  );

  const form = useFormik({
    validationSchema,
    onSubmit: handleSubmit,
    enableReinitialize: true,
    initialValues: questions.reduce<Record<string, string>>((acc, current) => {
      acc[current.id as string] = '';
      return acc;
    }, {}),
  });

  useEffect(() => {
    if (groupId) {
      form.resetForm();
      group$.fetchQuestions(groupId);
    }
  }, [groupId]);

  return (
    <InputDialog
      isOpen={dialog.isOpen}
      onClose={handleClose}
      paperProps={{ 'data-hook': GROUP_QUESTIONS_DIALOG_ROOT }}
    >
      <DialogTitle
        title={t('groups-web.membership-questions.title')}
        subtitle={t('groups-web.membership-questions.subtitle', {
          groupName: group.name,
        })}
      />
      <DialogContent divider>
        <SwitchTransition>
          {(() => {
            if (status.questions.loading) {
              return (
                <Fade key="loader">
                  <List gap="SP2" disablePadding>
                    <GroupQuestionSkeleton />
                    <GroupQuestionSkeleton />
                    <GroupQuestionSkeleton />
                    <GroupQuestionSkeleton />
                    <GroupQuestionSkeleton />
                  </List>
                </Fade>
              );
            }

            return (
              <Fade key="viewer">
                <List gap="SP2" disablePadding>
                  {questions.map((question, index) => (
                    <GroupQuestion
                      bw
                      index={index + 1}
                      key={question.id}
                      error={!!form.errors[question.id!]}
                      errorMessage={form.errors[question.id!] as string}
                      required={question.required}
                      question={question.text as string}
                      {...form.getFieldProps(question.id as string)}
                    />
                  ))}
                </List>
              </Fade>
            );
          })()}
        </SwitchTransition>
      </DialogContent>
      <DialogActions>
        <Button bw secondary fullWidth={isMobile} onClick={handleClose}>
          {t('groups-web.cancel')}
        </Button>
        <GroupMembershipButton
          bw
          fullWidth={isMobile}
          onClick={form.submitForm}
          groupId={groupId}
          data-hook={GROUP_QUESTIONS_DIALOG_SUBMIT_BUTTON}
        >
          {t('groups-web.submit')}
        </GroupMembershipButton>
      </DialogActions>
    </InputDialog>
  );

  function handleClose() {
    application$.closeDialog('groupQuestions');
  }

  function handleSubmit() {
    const answers = Object.entries(form.values).map(([id, text]) => ({
      id,
      text,
    }));

    group$.join({
      ...(dialog.params as JoinRequest),
      membershipQuestionAnswers: answers,
    });
  }
}
