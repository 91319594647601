import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Badge, BadgePriority } from 'wix-ui-tpa';

import { selectHasAdminRole } from 'store/groups/selectors';

import { classes } from './AdminBadge.st.css';

type IAdminBadgeProps =
  | {
      groupId: string;
      isAdmin?: never;
    }
  | {
      groupId?: never;
      isAdmin: boolean;
    };

export function AdminBadge(props: IAdminBadgeProps) {
  const { t } = useTranslation();

  const isAdmin = useSelector(selectHasAdminRole(props.groupId as string));

  if (!isAdmin && !props.isAdmin) {
    return null;
  }

  return (
    <Badge priority={BadgePriority.light} className={classes.root}>
      {t('groups-web.roles.group_admin')}
    </Badge>
  );
}

AdminBadge.displayName = 'AdminBadge';
