import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Dialog } from '../Dialog';

import { classes } from './AlertDialog.st.css';

interface IAlertDialogProps extends React.ComponentProps<typeof Dialog> {}

export function AlertDialog(props: IAlertDialogProps) {
  const { className, ...rest } = props;
  const { isMobile } = useEnvironment();

  return (
    <Dialog
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
      })}
      {...rest}
    />
  );
}

AlertDialog.displayName = 'AlertDialog';
