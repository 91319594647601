import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { Box } from 'wui/Box';

import { CONTAINER_ROOT } from 'wui/Container/dataHooks';
import { classes } from './Container.st.css';

interface IContainerProps extends React.ComponentProps<typeof Box> {
  fluid?: boolean;
}

export const Container: React.FC<IContainerProps> = (props) => {
  const { children, className, fluid, ...rest } = props;

  const { isMobile } = useEnvironment();

  return (
    <Box
      data-hook={CONTAINER_ROOT}
      className={cls(classes.root, className, {
        [classes.fluid]: fluid,
        [classes.mobile]: isMobile,
      })}
      {...rest}
    >
      {children}
    </Box>
  );
};

Container.displayName = 'Container';
Container.defaultProps = {
  direction: 'vertical',
};
