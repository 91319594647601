import React from 'react';
import type { WidgetProps } from '@wix/yoshi-flow-editor';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import { ThemeProvider } from 'common/context/theme/ThemeContext';
import { SocialGroupsApp } from 'common/components/SocialGroupsApp';

import { useShadowStyles } from 'common/hooks/useShadowStyles';

import { COMPONENT } from 'settings/consts';

import type { IVMProps } from '../../../vm/types';

import { Group } from './Group';
import { GROUP_WRAPPER } from './dataHooks';

COMPONENT.name = 'GroupPage';

export default function Widget(props: WidgetProps<IVMProps>) {
  const shadowStyles = useShadowStyles();

  return (
    <SocialGroupsApp {...props}>
      <ThemeProvider host={props.host}>
        <div data-hook={GROUP_WRAPPER} ref={shadowStyles.onRefChange}>
          <Group />
        </div>
      </ThemeProvider>
    </SocialGroupsApp>
  );
}
