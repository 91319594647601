import React, { useContext } from 'react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { PortalContext } from './context';

export function usePortalContainer() {
  const container = useContext(PortalContext);
  const { isSSR } = useEnvironment();

  return React.useMemo(() => {
    if (isSSR) {
      return undefined;
    }

    const id = `${container}__portal-container`;
    const existingContainer = document.getElementById(id);

    if (existingContainer) {
      return existingContainer;
    }

    const parent = document.getElementById('SITE_CONTAINER') || document.body;
    const el = document.createElement('div');

    el.id = id;
    el.className = container;

    return parent.appendChild(el);
  }, [isSSR]);
}
