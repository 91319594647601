import React from 'react';
import {
  ErrorMonitorBoundary,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import { WixCommentsApiProvider } from '@wix/comments-ooi-client';

import type { IVMProps } from '../../../vm/types';
import { SocialGroupStore } from 'store/SocialGroupsStore';

import { PortalContext } from 'wui/Portal/context';
import { ErrorState } from 'wui/ErrorState';

import { Router } from 'common/router';
import { ControllerProvider } from 'common/context/controller';

import { Toasts } from '../Toasts';
import { GroupMembershipDialogs } from '../GroupMembership';

interface ISocialGroupsAppProps extends WidgetProps<IVMProps> {
  children: React.ReactNode;
}

export function SocialGroupsApp(props: ISocialGroupsAppProps) {
  const { children, store, ...rest } = props;

  const { t } = useTranslation();

  return (
    <ErrorMonitorBoundary
      fallback={<ErrorState subtitle={t('groups-web.error-state.subtitle')} />}
    >
      <SocialGroupStore state={store}>
        <Router>
          <PortalContext.Provider value={props.host.id}>
            <ControllerProvider {...rest}>
              <WixCommentsApiProvider {...rest}>
                {children}
                <Toasts />
                <GroupMembershipDialogs />
              </WixCommentsApiProvider>
            </ControllerProvider>
          </PortalContext.Provider>
        </Router>
      </SocialGroupStore>
    </ErrorMonitorBoundary>
  );
}
