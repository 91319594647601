import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { Paper } from '../Paper';
import { Typography } from '../Typography';

import { classes } from './EmptyState.st.css';

type EmptyStateVariant = 'page' | 'section';

interface IEmptyStateProps extends TPAComponentProps {
  centered?: boolean;
  variant?: EmptyStateVariant;

  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  action?: React.ReactElement;

  paperProps?: React.ComponentProps<typeof Paper>;
}

export function EmptyState(props: IEmptyStateProps) {
  const { className, paperProps } = props;

  const { isMobile } = useEnvironment();

  let title: React.ReactElement;
  let subtitle: React.ReactElement;

  const isLarge = props.variant === 'page' && !isMobile;

  if (props.title && (props.title as React.ReactElement).type !== Typography) {
    title = (
      <Typography variant={isLarge ? 'h2-24' : 'h2-20'}>
        {props.title}
      </Typography>
    );
  } else {
    title = props.title as React.ReactElement;
  }

  if (
    props.subtitle &&
    (props.subtitle as React.ReactElement).type !== Typography
  ) {
    subtitle = <Typography variant="p2-16">{props.subtitle}</Typography>;
  } else {
    subtitle = props.subtitle as React.ReactElement;
  }

  return (
    <Paper
      data-hook={props['data-hook']}
      className={cls(classes.root, className, {
        [classes.mobile]: isMobile,
        [classes.centered]: props.centered,
        [classes.page]: props.variant === 'page',
        [classes.section]: props.variant === 'section',
      })}
      shadow={false}
      sideBorders={false}
      topBottomBorders={false}
      {...paperProps}
    >
      {title &&
        React.cloneElement(title, {
          ...title.props,
          className: cls(title.props.className, classes.title),
        })}

      {subtitle &&
        React.cloneElement(subtitle, {
          ...subtitle.props,
          className: cls(subtitle.props.className, classes.subtitle),
        })}

      {props.action &&
        React.cloneElement(props.action, {
          ...props.action.props,
          className: cls(props.action.props.className, classes.action),
        })}
    </Paper>
  );
}

EmptyState.displayName = 'EmptyState';
EmptyState.defaultProps = {
  centered: true,
  variant: 'page' as EmptyStateVariant,
};
