import React from 'react';
import cls from 'classnames';
import { useStyles } from '@wix/tpa-settings/react';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { appearanceStylesParams } from 'settings/appearance';

import { classes } from './Paper.st.css';

interface IPaperProps extends TPAComponentProps {
  as?: React.ElementType;
  children?: React.ReactNode;

  role?: 'dialog';
  bw?: boolean;
  shadow?: boolean;
  sideBorders?: boolean;
  topBottomBorders?: boolean;
}

export function Paper(props: IPaperProps) {
  const {
    children,
    className,
    shadow,
    sideBorders,
    topBottomBorders,
    bw,
    ...rest
  } = props;

  const styles = useStyles();

  const Element = props.as as React.ElementType;

  return (
    <Element
      className={cls(classes.root, className, {
        [classes.bw]: bw,
        [classes.withShadow]:
          shadow && styles.get(appearanceStylesParams.applyShadow),
        [classes.sideBorders]: sideBorders,
        [classes.topBottomBorders]: topBottomBorders,
      })}
      {...rest}
    >
      {props.children}
    </Element>
  );
}

Paper.displayName = 'Paper';
Paper.defaultProps = {
  as: 'div',
  shadow: true,
  sideBorders: true,
  topBottomBorders: true,
};
