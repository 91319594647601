import React from 'react';
import { IThemeContextValue } from './Theme';

export const ThemeContext = React.createContext<IThemeContextValue>(
  {} as IThemeContextValue,
);

ThemeContext.displayName = 'ThemeContext';

interface IThemeProviderProps extends IThemeContextValue {
  children: React.ReactNode;
}

export function ThemeProvider(props: IThemeProviderProps) {
  const [state] = React.useState<IThemeContextValue>(props);

  return (
    <ThemeContext.Provider value={state}>
      {props.children}
    </ThemeContext.Provider>
  );
}
