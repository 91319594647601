import { curryRight } from 'lodash';
import { createSelector } from 'reselect';

import { MembershipStatus } from '@wix/ambassador-social-groups-v2-group-member/types';
import { Type } from '@wix/ambassador-social-groups-v2-group/types';

import { selectGroup } from 'store/groups/selectors';
import { selectIsSiteAdmin } from 'store/application/selectors';

export const selectButtonLabel = curryRight(
  createSelector(
    selectGroup,
    selectIsSiteAdmin,
    (_: any, __: any, isMobile: boolean) => isMobile,
    (group, isSiteAdmin, isMobile) => {
      const requiresAdminApproval =
        group.accessRestriction?.type === Type.ADMIN_APPROVAL;

      switch (group.membership) {
        case MembershipStatus.JOINED:
          return 'groups-web.joined';

        case MembershipStatus.PENDING:
          return 'groups-web.pending';

        default:
        case MembershipStatus.UNKNOWN_STATUS:
          if (!requiresAdminApproval || isSiteAdmin || isMobile) {
            return 'groups-web.join';
          }

          return 'groups-web.request-to-join';
      }
    },
  ),
  3,
);
